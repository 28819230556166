<template>
  <div class="mb-[90px]">
    <div class="pb-6 px-2 mb-10" id="mainDiv">
      <div class="flex items-center justify-between border-0 border-b border-solid border-gainsboro pb-4">
        <p class="font-medium md:text-[32px] text-[24px] text-black">{{ $t('New order') }}</p>
        <span @click="handleClose" class="material-symbols-rounded cursor-pointer text-[32px]">close</span>
      </div>

      <div class="flex text-center justify-start mt-4">
        <div class="sm:w-auto w-full" v-for="(tab, index) in tabs" :key="index">
          <p @click="tab.value === activeTab ? '' : changeTab(tab.value)" :class="`font-medium whitespace-nowrap md:text-[18px] text-[14px] sm:py-3 sm:px-[33px] px-2 py-3 rounded-tr-md rounded-tl-md cursor-pointer leading-tight ${tab.value === $route.query.tab ? 'text-primary bg-active-tab' : 'border-0 border-b border-solid border-steam text-industrial-revolution'}`">
            {{ $t(tab.name) }}
          </p>
        </div>
      </div>
      <div class="mt-6 grid lg:grid-cols-3 md:grid-cols-4 grid-cols-2 gap-[16px]">
        <div class="col-span-2 flex flex-col gap-[16px]">
          <div v-if="activeTab !== 'cash-collections'" class="border border-solid border-gainsboro rounded-lg md:pb-[35px] pb-[31px] md:px-[22px] px-[16px] pt-[25px] bg-white">
            <p class="text-black font-medium md:text-[20px] text-[16px]">{{ $t('create anew order') }}</p>
            <order-details :restData="restData" @restData="restData = $event" :activeTab="activeTab" :orderDetails="orderDetails" @orderDetails="orderDetails = $event" ref="orderDetails" :changebleTabValue="changebleTabValue"  @changebleTabValue="changebleTabValue = $event"/>
          </div>
          <div class="border border-solid border-gainsboro rounded-lg md:pb-[35px] pb-[31px] md:px-[22px] px-[16px] pt-[25px] bg-white">
            <customer-details @searchPhoneVal="searchPhoneVal = $event" :restData="restData" @restData="restData = $event" :changebleTabValue="changebleTabValue" :activeTab="activeTab" @changebleTabValue="changebleTabValue = $event" :customerData="customerData" @customerData="customerData = $event" @activeTab="activeTab = $event" ref="customerDetails"/>
          </div>
          <div class="border border-solid border-gainsboro rounded-lg md:py-[26px] md:px-[22px] p-[16px] bg-white">
            <shipping-fees :orderPrice="orderPrice" :serviceLevels="serviceLevels"/>
          </div>
        </div>
        <div v-if="activeTab !== 'cash-collections'" class="lg:col-span-1 md:col-span-2 col-span-2 border border-solid border-gainsboro rounded-lg bg-white">
          <package-details :activeTab="activeTab" :showErrorMassage="showErrorMassage" @showErrorMassage="showErrorMassage = $event" @packageDetails="packageDetails = $event" ref="packageDetails"/>
        </div>
      </div>
    </div>
    <div id="secondDiv" class="fixed z-[99992] bottom-0 pt-[24px] pb-[32px] flex justify-end gap-3 border-0 border-t border-solid border-gainsboro bg-layout">
      <vs-button @click="addOrder(false)" class="sm:text-lg text-sm sm:flex-grow-0 flex-grow font-semibold px-6" color="#8599B3">{{ $t('Confirm & Create New') }}</vs-button>
      <vs-button @click="addOrder(true)" class="sm:text-lg text-sm sm:flex-grow-0 flex-grow font-semibold">{{ $t('Confirm') }}</vs-button>
    </div>
  </div>
</template>

<script>
import PackageDetails from './create-new-order/PackageDetails.vue'
import OrderDetails from './create-new-order/OrderDetails.vue'
import CustomerDetails from './create-new-order/CustomerDetails.vue'
import ShippingFees from './create-new-order/ShippingFees.vue'
import { sendRequest } from '../../http/axios/requestHelper'
import common from '../../assets/utils/common'
import { previousRoute } from '@/router'
import i18nData from '../../i18n/i18nData.js'

export default {
  data () {
    return {
      tabs: [
        {
          name: 'Delivery',
          value: 'delivery-orders'
        },
        {
          name: 'ReturnTab',
          value: 'returns'
        },
        {
          name: 'Exchange',
          value: 'exchanges'
        },
        {
          name: 'CashCollectionOrderTab',
          value: 'cash-collections'
        }
      ],
      activeTab: '',
      orderDetails: {
        cash_amount: '',
        pickupPoint: '',
        is_counter_dropoff: '',
        merchant_order_reference: '',
        order_notes: '',
        refund: false
      },
      packageDetails: {},
      customerData: {
        phone: '',
        full_name: '',
        secondary_phone: '',
        street_building: '',
        zone: '',
        governorate: ''
      },
      subscriptionId: '',
      serviceLevels: [],
      orderPrice: {},
      changebleTabValue: {
        newTab: '',
        changeable: false
      },
      restData: false,
      formFields: {
        customerDetails: [],
        orderDetails: []
      },
      previousRouter: '',
      searchPhoneVal: '',
      showErrorMassage: false,
      originalOrderValues: ['', '', '', '', false]
    }
  },
  watch: {
    searchPhoneVal (val) {
      if (val.length > 0) {
        this.customerData.phone = val
      }
    },
    '$route.params.lang' () {
      this.changeTab(this.activeTab, 'fromCreation')
    },
    '$route.query.tab' (val) {
      this.changeTab(val, 'fromCreation')
    },
    orderDetails: {
      handler () {
        if (this.customerData.governorate && this.packageDetails.number_of_packages > 0) {
          this.loadEstimatedPrice()
        }
      },
      deep: true
    },
    customerData: {
      async handler (val) {
        if (val.governorate && this.packageDetails.number_of_packages > 0) {
          await this.loadServicesAvailability(val.governorate.id)
          this.loadEstimatedPrice()
        }
      },
      deep: true
    },
    packageDetails: {
      handler () {
        if (this.customerData.governorate && this.packageDetails.number_of_packages > 0) {
          this.loadEstimatedPrice()
        }
      },
      deep: true
    },
    'activeTab' () {
      this.resetForm()
    }
  },
  methods: {
    changeTab (tab, type) {
      const orderValues = Object.values(this.orderDetails).slice()
      orderValues.splice(1, 1)
      const orderValuesDiff = orderValues.filter((e) => this.originalOrderValues.every((val) => val !== e))
      
      if (type) {
        this.activeTab = tab
        this.$router.push({
          query: {
            tab: this.activeTab
          }
        }).catch(() => {})
      } else {
        this.changebleTabValue = {
          changeable: true,
          newTab: tab
        }
        if (orderValuesDiff.length > 0 || Object.values(this.customerData).filter(item => item !== '').length > 0 || (this.packageDetails.packages && this.packageDetails.packages.length > 0)) {
          this.$vs.dialog({
            color: 'danger',
            title: i18nData[this.$i18n.locale]['Confirm'],
            text: i18nData[this.$i18n.locale]['Are you sure you want to switch to another tab? All entered info will be deleted.'],
            acceptText: i18nData[this.$i18n.locale]['accept'],
            accept: this.resetForm
          })
        } else {
          this.resetForm()
        }
      }
    },
    async loadEstimatedPrice () {
      let type = ''
      switch (this.activeTab) {
      case 'delivery-orders':
        type = 'delivery'
        break
      case 'returns':
        type = 'return'
        break
      case 'cash-collections':
        type = 'cash-collection'
        break
      case 'exchanges':
        type = 'exchange'
        break
      default:
        break
      }
      await this.loadServicesAvailability(this.customerData.governorate.id)
      const orderData = {
        to_governorate: this.customerData.governorate ? this.customerData.governorate.id : ''
      }
      if (type === 'return') {
        orderData.cash_amount = this.orderDetails.cash_amount ? Number(this.orderDetails.cash_amount) : 0
      } else if (type === 'delivery') {
        orderData.cash_amount = this.orderDetails.cash_amount ? Number(this.orderDetails.cash_amount) : 0
        orderData.is_customer_allowed_to_open_packages = this.packageDetails.is_customer_allowed_to_open_packages
      } else if (type === 'exchange') {
        orderData.refund = this.orderDetails.refund
        orderData.cash_amount = !this.orderDetails.refund ? Number(this.orderDetails.cash_amount) : 0
        orderData.refund_amount = this.orderDetails.refund ? Number(this.orderDetails.cash_amount) : 0
        orderData.service_level = this.serviceLevels.length > 0 ? this.serviceLevels[0].id : 0
      } else {
        orderData.amount = this.orderDetails.cash_amount ? Number(this.orderDetails.cash_amount) : 0
      }
      orderData.packages = []
      if (type === 'delivery' || type === 'return' || type === 'exchange') {
        orderData.packages = this.packageDetails.packages.map(item => item.package_size)
      }
      sendRequest(true, false, this, `api/${this.activeTab === 'exchanges' ? 'v2' : 'v1'}/pricing/orders/${type}/`, 'post', orderData, true,
        (response) => {
          this.orderPrice = response.data
        }
      )
    },
    async addOrder (isAddNew) {
      if (this.packageDetails.number_of_packages === 0) {
        this.showErrorMassage = true
      }
      const isValid = await this.validateAllComponents()
      if (!isValid || (this.packageDetails.number_of_packages === 0 && this.activeTab !== 'cash-collections')) {
        return
      }
      const orderObj = {
        is_counter_dropoff: this.orderDetails.is_counter_dropoff,
        merchant_order_reference: this.orderDetails.merchant_order_reference,
        order_notes: this.orderDetails.order_notes,
        customer: {
          full_name: this.customerData.full_name,
          phone: this.customerData.phone,
          secondary_phone: this.customerData.secondary_phone ? this.customerData.secondary_phone : null,
          address: {
            line_1: this.customerData.street_building,
            line_2: '.',
            zone: this.customerData.zone.id
          }
        },
        packages: this.packageDetails.packages,
        is_customer_allowed_to_open_packages: this.packageDetails.is_customer_allowed_to_open_packages
      }
      if (!orderObj.is_counter_dropoff) {
        orderObj.pickup_point = this.orderDetails.pickupPoint ? this.orderDetails.pickupPoint.id : null
      }
      if (this.activeTab === 'exchanges') {
        orderObj.amount = this.customerData.cash_collections
      }
      if (this.activeTab !== 'exchanges') {
        orderObj.cash_amount = this.orderDetails.cash_amount === '' ? 0 : this.orderDetails.cash_amount
      }
      if (this.orderDetails.refund) {
        orderObj.refund_amount = this.orderDetails.cash_amount
      } else {
        orderObj.cash_amount = this.orderDetails.cash_amount === '' ? 0 : this.orderDetails.cash_amount
      }
      if (this.activeTab === 'returns') {
        orderObj.order_notes = `${orderObj.order_notes  } ${  this.packageDetails.packages[0].description}`
      }
      sendRequest(false, false, this, `api/${this.activeTab === 'exchanges' ? 'v2' : 'v1'}/${this.activeTab}/`, 'post', orderObj, true,
        async () => {
          this.$vs.notify({
            title: this.$t('Success'),
            text: this.$t('Order created successfully'),
            color: 'success',
            position: 'top-center'
          })
          if (isAddNew) {
            await this.navigateToMerchantView()
          } else {
            this.resetForm()
          }
        }
      )
    },
    async navigateToMerchantView () {
      const routesMap = {
        'delivery-orders': 'merchant-shipments',
        'returns': 'merchant-returns-view',
        'cash-collections': 'merchant-cash-collections-view',
        'exchanges': 'merchant-exchanges-view'
      }
      const targetRoute = routesMap[this.activeTab]
      if (targetRoute && this.$route.name !== targetRoute) {
        await this.$router.push({ name: targetRoute }).catch(() => {})
      }
    },
    resetForm () {
      this.orderDetails = {
        cash_amount: '',
        pickupPoint: '',
        is_counter_dropoff: '',
        merchant_order_reference: '',
        order_notes: '',
        refund: false
      }
      this.customerData = {
        phone: '',
        full_name: '',
        secondary_phone: '',
        street_building: '',
        zone: '',
        governorate: ''
      }
      this.restData = true
      if (this.$refs.customerDetails) {
        this.$refs.customerDetails.$validator.reset()
      }
      if (this.$refs.orderDetails) {
        this.$refs.orderDetails.$validator.reset()
      }
      if (this.$refs.packageDetails) {
        this.$refs.packageDetails.$validator.reset()
      }
      this.changeTab(this.changebleTabValue.newTab ? this.changebleTabValue.newTab : this.activeTab, 'fromCreation')
    },
    loadServicesAvailability (id) {
      sendRequest(true, false, this, `api/v1/services-availability/?governorate_id=${id}&subscription_id=${this.subscriptionId}`, 'get', null, true, 
        (response) => {
          const serviceLevels = response.data.map(element => element.service_level)
          const key = 'id'
          this.serviceLevels = [...new Map(serviceLevels.map(item => [item[key], item])).values()]
          this.serviceLevels = this.serviceLevels.reverse()
        }
      )
    },
    async validateAllComponents () {
      const validations = []
      if (this.$refs.customerDetails) {
        await this.$refs.customerDetails.$validator.attach()
        const customerValid = await this.$refs.customerDetails.$validator.validateAll()
        validations.push({
          component: 'Customer Details',
          isValid: customerValid,
          errors: this.$refs.customerDetails.errors.items
        })
      }
      if (this.$refs.orderDetails && this.activeTab !== 'cash-collections') {
        await this.$refs.orderDetails.$validator.attach()
        const orderValid = await this.$refs.orderDetails.$validator.validateAll()
        validations.push({
          component: 'Order Details',
          isValid: orderValid,
          errors: this.$refs.orderDetails.errors.items
        })
      }
      if (this.$refs.packageDetails && this.activeTab !== 'cash-collections') {
        await this.$refs.packageDetails.$validator.attach()
        const packageValid = await this.$refs.packageDetails.$validator.validateAll()
        validations.push({
          component: 'Order Details',
          isValid: packageValid,
          errors: this.$refs.packageDetails.errors.items
        })
      }
      const customValidations = this.validateCustomFields()
      validations.push(...customValidations)
      const failedValidations = validations.filter(v => !v.isValid)
      if (failedValidations.length > 0) {
        return false
      }
      return true
    },
    validateCustomFields () {
      const validations = []
      if (!this.customerData.zone && !this.autoZoning) {
        validations.push({
          component: 'Customer Details',
          isValid: false,
          errors: ['Zone is required']
        })
      }
      if (this.activeTab === 'cash-collections') {
        const cashAmount = parseFloat(this.customerData.cash_collections)
        if (!cashAmount || cashAmount <= 0) {
          validations.push({
            component: 'Customer Details',
            isValid: false,
            errors: ['Valid cash amount is required']
          })
        }
      }
      return validations
    },
    handleClose () {
      if (previousRoute.name) {
        this.$router.push({ name: this.previousRouter }).catch(() => {})
      } else {
        this.$router.push({ name: 'merchant-dashboard' }).catch(() => {})
      }
    }
  },
  components: {
    PackageDetails,
    OrderDetails,
    CustomerDetails,
    ShippingFees
  },
  mounted () {
    const mainDiv = document.getElementById('mainDiv')
    const secondDiv = document.getElementById('secondDiv')
    const syncDimensions = () => {
      if (mainDiv && secondDiv) {
        secondDiv.style.width = `${mainDiv.offsetWidth}px`
      }
    }
    syncDimensions()
    const resizeObserver = new ResizeObserver(syncDimensions)
    if (mainDiv) {
      resizeObserver.observe(mainDiv)
    }
  },
  created () {
    this.previousRouter = previousRoute.name
    this.activeTab = this.tabs[0].value
    const merchantData = common.checkMerchantInLocalStorage(this)
    let user = {}
    merchantData.then(results => {
      user = results.merchantData
      this.subscriptionId = user.subscription.id
    })
    this.changeTab(this.tabs.some(tab => tab.value === this.$route.query.tab) ? this.$route.query.tab : this.tabs[0].value, 'fromCreation')
  }
}
</script>

<style lang="scss">
@media (max-width: 767px) {
  #content-area .content-wrapper {
    min-height: unset;
  }
}
.bg-active-tab {
  background-color: #EBF0FF;
  border-bottom: 1.5px solid #1C5BFE;
}
</style>