<template>
  <div class="flex flex-col h-full">
    <!-- Options -->
    <div :class="`p-5 grid lg:grid-cols-${packageObj.options.length} md:grid-cols-1 grid-cols-${packageObj.options.length} gap-2 border-0 border-b border-solid border-steam`">
      <div v-for="(item, index) in packageObj.options" :key="index" class="package-checkbox-order h-full">
        <input v-model="item.value" type="checkbox" :name="item.id" :id="item.id">
        <label class="flex h-full items-center md:gap-3 gap-2 md:px-[22px] px-4 py-[14px]" :class="packageObj.options.length === 1 ? 'justify-center' : '' " :for="item.id">
          <img :src="item.image" :width="item.width" :height="item.height" class="md:w-auto max-w-[18px] max-h-[18px]" :alt="item.id">
          <p class="text-sm" :class="packageObj.options.length === 1 ? 'text-center w-full' : '' ">{{ $t(item.name) }}</p>
        </label>
      </div>
    </div>

    <!-- Packages -->
    <div class="p-5 flex flex-col gap-3">
      <div v-for="(item, index) in packageObj.packagesSizes" :key="index">
        <div :class="`border border-solid ${item.count > 0 ? 'border-primary' : 'border-stoic-white'} rounded flex justify-between overflow-hidden`">
          <div class="flex items-center gap-3">
            <div class="rounded px-5 py-2 flex items-center bg-beluga">
              <img src="@/assets/images/pages/flyer.svg" class="w-10" alt="flyer">
            </div>
            <div class="text-sm text-black">
              <p class="inline">{{ $t(item.name.match(/^.*?(?=\s?\()/)[0]) }}</p>
              <p v-if="item.count > 0" class="text-primary inline">{{ ' (' + item.count + ')' }}</p>
              <p>{{ $t(item.name.match(/\(.*\)/)[0]) }}</p>
            </div>
          </div>
          <button v-if="item.count === 0" :disabled="numberOfPackages() >= 4" @click="changePackageCount(index, 'increase')" :class="`${numberOfPackages() >= 4 ? 'bg-grey-light text-grey' : 'bg-primary text-white'} self-center mx-3 p-1 outline-none border-0 cursor-pointer rounded-sm flex items-center justify-center`">
            <span class="material-symbols-outlined text-xl">add</span>
          </button>
          <div v-else class="grid grid-cols-1 border-0 border-l border-solid border-silver">
            <button :disabled="numberOfPackages() >= 4" @click="changePackageCount(index, 'increase')" class="bg-transparent px-3 outline-none border-0 border-b border-solid border-silver cursor-pointer flex items-center justify-center">
              <span :class="`material-symbols-outlined text-xl ${numberOfPackages() >= 4 ? 'text-grey' : 'text-primary'}`">add</span>
            </button>
            <button :disabled="numberOfPackages() <= 0" @click="changePackageCount(index, 'decrease')" class="bg-transparent px-3 outline-none border-0 cursor-pointer flex items-center justify-center">
              <span :class="`material-symbols-outlined text-xl ${numberOfPackages() <= 0 ? 'text-grey' : 'text-primary'}`">remove</span>
            </button>
          </div>
        </div>
      </div>
      <p class="text-danger text-sm" v-if="showErrorMassage">{{ $t('Please select the flyers number in this order') }}</p>
    </div>
    <!-- Description -->
    <div class="flex flex-grow p-5 border-0 border-t border-solid border-steam">
      <textarea name="package description" v-validate="activeTab === 'returns' ? 'required' : ''" v-model="packageObj.description" rows="10" :placeholder="$t('Describe your package in Arabic (No. of items and items type)')" class="resize-none w-full p-3 rounded bg-transparent border border-solid border-compact-disc-grey outline-none" style="font-family: inherit;"></textarea>
    </div>
    <p class="p-5 pt-0 text-danger text-sm" v-show="errors.has('package description')">{{ $t('Package Description is required') }}</p>
  </div>
</template>

<script>
export default {
  props: ['activeTab', 'showErrorMassage'],
  data () {
    return {
      packageDetails: {
        number_of_packages: 0,
        packages: [],
        is_customer_allowed_to_open_packages: false
      },
      packageObj: {
        options: [],
        packagesSizes: [],
        description: ''
      }
    }
  },
  watch: {
    activeTab (val) {
      this.updatePackageObj(val)
    },
    showErrorMassage (val) {
      this.$emit('showErrorMassage', val)
    },
    packageObj: {
      handler (val) {
        this.packageDetails = {
          is_customer_allowed_to_open_packages: val.options.length > 1 ? val.options[1].value : false,
          number_of_packages: this.numberOfPackages(),
          packages: val.packagesSizes.filter(item => item.count > 0).map(item => {
            return Array.from({ length: item.count }, () => {
              return {
                package_size: item.id,
                description: val.description,
                fragile: val.options[0].value
              }
            })
          }).flat()
        }
        if (val.packagesSizes.filter(item => item.count > 0).length > 0) {
          this.showErrorMassage = false
        }
        this.$emit('packageDetails', this.packageDetails)
      },
      deep: true
    }
  },
  methods: {
    changePackageCount (packageIndex, type) {
      if (type === 'increase') {
        this.packageObj.packagesSizes[packageIndex].count += 1
      } else {
        this.packageObj.packagesSizes[packageIndex].count -= 1
      }
      this.packageObj.packagesSizes = [...this.packageObj.packagesSizes]
    },
    numberOfPackages () {
      return this.packageObj.packagesSizes.reduce((sum, { count }) => sum + count, 0)
    },
    updatePackageObj (tab) {
      switch (tab) {
      case 'delivery-orders':
      case 'exchanges':
        this.packageObj = {
          options: [
            {
              name: 'Fragile Package',
              image: require('../../../assets/images/pages/fragile-package.svg'),
              width: 16,
              height: 23,
              id: 'fragile',
              value: false
            },
            {
              name: 'Allow To Open',
              image: require('../../../assets/images/pages/allow-to-open.png'),
              width: 26,
              height: 26,
              id: 'allowToOpen',
              value: false
            }
          ],
          packagesSizes: [],
          description: ''
        }
        this.loadPackageSizes()
        break
      case 'returns':
        this.packageObj = {
          options: [
            {
              name: 'Fragile Package',
              image: require('../../../assets/images/pages/fragile-package.svg'),
              width: 16,
              height: 25,
              id: 'fragile',
              value: false
            }
          ],
          packagesSizes: [],
          description: ''
        }
        this.loadPackageSizes()
        break
      default:
        break
      }
    },
    loadPackageSizes () {
      this.packageObj.packagesSizes = [
        {
          count: 0,
          description: 'Small Flyer (25x35cm)',
          id: 1,
          name: 'Small Flyer (25x35cm)'
        },
        {
          count: 0,
          description: 'Medium Flyer (40x35cm)',
          id: 2,
          name: 'Medium Flyer (40x35cm)'
        },
        {
          count: 0,
          description: 'Large Flyer (45x50cm)',
          id: 3,
          name: 'Large Flyer (45x50cm)'
        },
        {
          count: 0,
          description: 'Extra Large Flyer (50x60cm)',
          id: 4,
          name: 'Extra Large Flyer (50x60cm)'
        }
      ]
    }
  },
  created () {
    this.updatePackageObj(this.activeTab)
  }
}
</script>

<style lang="scss">
.package-checkbox-order {
  input[type="checkbox"] {
    display: none;
  }
  input[type="checkbox"] + label {
    cursor: pointer;
    user-select: none;
    border: 1px solid #737373;
    color: #475C6F;
    border-radius: 4px;
    img {
      -webkit-filter: grayscale(1);
      filter: grayscale(1);
    }
  }
  input[type="checkbox"]:checked + label {
    border: 1px solid #1C5BFE;
    color: #1C5BFE;
    background-color: transparent;
    box-shadow: none;
    img {
      -webkit-filter: grayscale(0);
      filter: grayscale(0);
    }
  }
}
</style>