<template>
  <div>
    <p class="text-black font-medium mt-[24px] mb-[15px] md:text-base text-xs">{{
      activeTab === 'delivery-orders' ? $t('How would you like us to receive your order?') + ' *' : 
      activeTab === 'returns' ? $t('How would you like us to return your order?') + ' *' : 
      activeTab === 'exchanges' ? $t('How would you like us to pickup/return your order?') + ' *' : '' }}</p>
    <div class="grid grid-cols-2 gap-y-4 gap-x-6">
      <div class="grid gap-2 grid-cols-2 xl:col-span-1 col-span-2 w-full">
        <div v-for="(dropOff, index) in dropOffChoices" :key="index">
          <label :for="dropOff.value" class="h-[48px] flex items-center gap-2 leading-tight border border-solid p-4 rounded-md w-full cursor-pointer select-none" :class="dropOff.checked ? 'border-primary text-primary' : 'border-grey'" @click="checkedItem(dropOff, dropOffChoices)">
            <input name="dropOff" :id="dropOff.value" v-validate="'required'" type="radio" v-model="orderDetails.is_counter_dropoff" :value="dropOff.value"/>
            <span class="rounded-md font-medium">{{ activeTab === 'delivery-orders' ? $t(dropOff.name) : activeTab === 'returns' ? $t(dropOff.returnName) : activeTab === 'exchanges' ? $t(dropOff.exchangeName) : '' }}</span>
          </label>
        </div>
        <p class="text-danger text-sm" v-show="errors.has('dropOff')">{{ $t('This field is required') }}</p>
      </div>  
      <div v-if="activeTab === 'exchanges'" class="xl:col-span-1 col-span-2 gap-4 flex items-center h-[48px]">
        <div class="flex-shrink-0 border border-solid rounded-full border-grey px-1 py-1 flex justify-between items-center whitespace-no-wrap text-[10px] leading-tight cursor-pointer">
          <span :class="`${!orderDetails.refund ? 'bg-primary text-white' : 'text-black bg-transparent'} rounded-full py-2 px-3 transition ease-linear duration-150`" @click="orderDetails.refund = false">{{ $t('COD') }}</span>
          <span :class="`${orderDetails.refund ? 'bg-primary text-white' : 'text-black bg-transparent'} rounded-full py-2 px-3 transition ease-linear duration-150`" @click="orderDetails.refund = true">{{ $t('exchange Refund') }}</span>
        </div>
        <div class="relative w-full">
          <shipblu-input labelBackground="white" :labelPlaceholder="orderDetails.refund ? $t('Cash Refund') : $t('COD')" v-model="orderDetails.cash_amount" type="text" class="w-full" @blur="validateCashAmount">
            <p class="slot-item text-industrial-revolution">{{ $t(' EGP') }}</p>
          </shipblu-input>
        </div>
      </div>
      <div v-show="orderDetails.is_counter_dropoff === false && activeTab !== 'exchanges'" class="xl:col-span-1 col-span-2">
        <v-select class="w-full h-[48px] no-search" v-model="orderDetails.pickupPoint" v-validate="'required'"
          :placeholder="activeTab === 'delivery-orders' ? $t('pickup') : $t('Return Location')" name="pickup point" label="fullAddress" :options="pickupPoints"/>
        <p class="text-danger text-sm" v-show="errors.has('pickup point')">{{ $t('The pickup point field is required') }}</p>
      </div>
    </div>

    <div v-if="activeTab !== 'exchanges'" class="mt-4 grid grid-cols-2 gap-y-4 gap-x-6">
      <div class=" relative w-full">
        <shipblu-input labelBackground="white" :labelPlaceholder="activeTab === 'delivery-orders' ? $t('COD') : $t('Cash Refund')" v-model="orderDetails.cash_amount" type="text" class="w-full" @blur="validateCashAmount">
          <p class="slot-item text-industrial-revolution">{{ $t(' EGP') }}</p>
        </shipblu-input>
      </div>
      <shipblu-input labelBackground="white" :labelPlaceholder="$t('Refrence Number')" v-model="orderDetails.merchant_order_reference" type="text" class="w-full" />
    </div>
    <shipblu-input labelBackground="white" :labelPlaceholder="activeTab === 'delivery-orders' ? $t('Delivery Note') : activeTab === 'returns' ? $t('Return Note') : $t('Exchange Note')" v-model="orderDetails.order_notes" type="text" class="w-full mt-4" />
  </div>
</template>

<script>
import vSelect from 'vue-select'
import { sendRequest } from '../../../http/axios/requestHelper'
import common from '../../../assets/utils/common'
import ShipbluInput from '@/layouts/components/ShipbluInput.vue'

export default {
  props: ['activeTab', 'changebleTabValue', 'restData', 'orderDetails'],
  data () {
    return {
      pickupPoints: [],
      dropOffChoices: [
        {
          value: false,
          name: 'From pickup location',
          returnName: 'To my location',
          exchangeName: 'From/to pickup location',
          checked: false
        },
        {
          value: true,
          name: 'Dropoff at warehouse',
          returnName: 'To the warehouse',
          exchangeName: 'From/to warehouse',
          checked: false
        }
      ],
      merchant: {}
    }
  },
  watch: {
    'restData' (val) {
      if (val) {
        this.resetForm()
      }
    },
    'orderDetails.is_counter_dropoff' (val) {
      if (typeof (val) === 'object') {
        this.orderDetails.is_counter_dropoff = this.orderDetails.is_counter_dropoff.id
        this.$emit('orderDetails', this.orderDetails)
      }
    }
  },
  methods : {
    checkedItem (element, array) {
      array.forEach(item => {
        if (item.name === element.name) {
          item.checked = true
        } else {
          item.checked = false
        }
      })
    },
    loadPickupPoints () {
      sendRequest(true, false, this, `api/v1/merchants/${this.merchant.id}/pickup-points/?limit=60`, 'get', null, true, 
        (response) => {
          this.pickupPoints = response.data.results
          this.pickupPoints.forEach(element => {
            element.fullAddress = `${element.address.line_1  }, ${  element.address.line_2  } - ${  element.address.zone.name}`
            if (element.is_default) {
              this.orderDetails.pickupPoint = element
            }
          })
        }
      )
    },
    getMerchantData () {
      const merchantData = common.checkMerchantInLocalStorage(this)
      let user = {}
      merchantData.then(results => {
        user = results.merchantData
        this.merchant = user
        this.loadPickupPoints()
      })
    },
    validateCashAmount () {
      const value = parseFloat(this.orderDetails.cash_amount)
      if (isNaN(value)) {
        this.orderDetails.cash_amount = (0).toFixed(2)
      } else {
        this.orderDetails.cash_amount = value.toFixed(2)
      }
      this.$emit('orderDetails', this.orderDetails)
    },
    resetForm () {
      this.orderDetails = {
        cash_amount: '',
        pickupPoint: this.orderDetails.pickupPoint,
        is_counter_dropoff: '',
        merchant_order_reference: '',
        order_notes: '',
        refund: false
      }
      this.dropOffChoices.forEach(item => { item.checked = false })
      this.getMerchantData()
      this.$emit('orderDetails', this.orderDetails)
      if (this.changebleTabValue.newTab) {
        this.$router.push({
          query: {
            tab: this.changebleTabValue.newTab
          }
        }).catch(() => {})
      }
      this.$emit('activeTab', this.activeTab)
    }
  },
  components: {
    vSelect,
    ShipbluInput
  },
  mounted () {
    this.$emit('orderDetails', this.orderDetails)
  },
  created () {
    this.getMerchantData()
  }
}
</script>

<style lang="scss">
.vs__dropdown-toggle {
  height: 100%;
  border-radius: 5px;
}
.v-select.no-search {
  .vs__selected + input[type=search] {
    display: none;
  }
}
</style>